import Vue from "vue";
import Router from "vue-router";
import Quiz from "@/components/quiz/Quiz.vue";
import QuizResponse from "@/components/quiz/QuizResponse.vue";

Vue.use(Router);
const _dc918bc8 = () =>
  import("../src/views/homepage/index.vue" /* webpackChunkName: "pages/index" */).then(
    m => m.default || m
  );

const scrollBehavior = (to, from, savedPosition) => {
  // SavedPosition is only available for popstate navigations.
  if (savedPosition) {
    return savedPosition;
  } else {
    let position = {};
    // If no children detected
    if (to.matched.length < 2) {
      // Scroll to the top of the page
      position = { x: 0, y: 0 };
    } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
      // If one of the children has scrollToTop option set to true
      position = { x: 0, y: 0 };
    }
    // If link has anchor, scroll to anchor by returning the selector
    if (to.hash) {
      position = { selector: to.hash };
    }
    return position;
  }
};

export default new Router({
  mode: "history",
  base: "/",
  scrollBehavior,
  routes: [
    {
      path: "/",
      name: "home",
      component: _dc918bc8
    },
    {
      path: "/quiz",
      name: "quiz",
      component: Quiz
    },
    {
      path: "/quiz/:name",
      name: "response",
      component: QuizResponse
    },
    {
      path: "*",
      redirect: "/"
    }
  ],
  fallback: false
});
