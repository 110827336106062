<template>
  <div class="response-container">
    <div class="response-wrapper">
      <div class="name-wrapper">
        <h1 v-if="response.name">
          <span>{{ response.name }}</span> </h1>
      </div>
      <div class="image-desc-wrapper">
        <div class="image-wrapper" v-if="response.image">
          <img :src="buildImageUrl(response.image)">
        </div>
        <div class="description-wrapper">
          <h3> {{ response.description }} </h3>
        </div>
      </div>
      <div class="button-container">
        <div class="icon-container">
          <div class="icon-wrapper">
            <a class="icon fb" href="#" @click.prevent="shareToFacebook()">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a class="icon twitter" href="#" @click.prevent="shareToTwitter()">
              <i class="fab fa-twitter"></i>
            </a>
            <a class="icon email" href="#" @click.prevent="shareToEmail()">
              <i class="fas fa-envelope"></i>
            </a>
          </div>
        </div>
        <!-- <button v-if="playStatus == true" class="btn share" @click="refreshQuiz()">Partager
            <i class="fas fa-share"></i></button> -->
        <template v-if="playStatus == true">
          <button class="btn refresh" @click="refreshQuiz()">Reéssayer le
            quiz
            <i class="fas fa-sync-alt"></i>
          </button>
        </template>
        <template v-else>
          <button class="btn refresh" @click="refreshQuiz()">Jouer le quiz <i
              class="fas fa-reply"></i></button>
        </template>
      </div>

    </div>

  </div>
</template>

<script>
import resp from "@/components/quiz/answer.json";
export default {
  data() {
    return {
      answer: resp,
      response: "",
      playStatus: false,
      facebookAppID: 1662665607355206
    };
  },
  mounted() {
    this.getResult();
    this.checkPlayedStatus();
  },
  methods: {
    getResult() {
      let name = this.$route.params.name;
      if (name != "" && typeof this.answer[name] !== "undefined") {
        this.response = this.answer[name];
      } else {
        this.$router.push({
          name: "quiz"
        });
      }
    },
    checkPlayedStatus() {
      let status = localStorage.getItem("isPlayed");
      if (status == "true") {
        this.playStatus = true;
      } else {
        this.playStatus = false;
      }
    },
    refreshQuiz() {
      this.$router.push({
        name: "quiz"
      });
    },
    buildImageUrl(name) {
      return require(`../../assets/caricature/${name}.jpg`);
    },
    shareToFacebook() {
      let url =
        "https://www.facebook.com/dialog/share?app_id=" +
        this.facebookAppID +
        "&href=" +
        window.location.href +
        "&redirect_uri=" +
        window.location.href;
      window.open(url, "_blank");
    },
    shareToTwitter() {
      let url =
        "http://twitter.com/share?text=" +
        "Quel Parlementaire Êtes-Vous?" +
        "&url=" +
        window.location.href +
        "&hashtags=Mauritius";
      window.open(url, "_blank");
    },
    shareToEmail() {
      let url =
        "mailto:?subject=" +
        "Quel Parlementaire Êtes-Vous?" +
        window.location.href;
      window.open(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.response-container {
}

.response-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20%;
}

.name-wrapper {
  h1 {
    font-size: 45px;
    color: var(--color-text);
    text-align: center;
    margin: 0;
  }
}

.image-desc-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  img {
    width: 60%;
  }
}

.description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 25px;
    line-height: 32px;
  }
}

.button-container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.btn {
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  background: #84c859;
  font-weight: 600;
  cursor: pointer;

  &:last-child {
    margin-left: 20px;
  }

  i {
    margin-left: 10px;
  }
}

.icon-container {
  .icon-wrapper {
    display: flex;

    .icon {
      background: black;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: white;

      &.fb {
        background: #3b5998;
      }

      &.twitter {
        background: #38a1f3;
        margin: 0 10px;
      }
    }
  }
}

@media (max-width: $desktop) {
  .response-wrapper {
  }
  .name-wrapper {
    h1 {
      font-size: 35px;
    }
  }
}

@media (max-width: $tablet) {
  .response-wrapper {
    margin: 0;
  }
  .name-wrapper {
    h1 {
      font-size: 30px;
    }
  }
  .image-desc-wrapper {
    grid-template-columns: 1fr;
  }
  .image-wrapper {
    img {
      width: 35%;
    }
  }
  .description-wrapper {
    h3 {
      text-align: center;
      margin: 0 20px;
      font-size: 20px;
    }
  }

  .button-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn {
      margin: 20px 0 0 0;
    }
  }
}

@media (max-width: $mobile) {
  .name-wrapper {
    h1 {
      margin: 20px;
      font-size: 24px;
    }
  }
  .image-wrapper {
    img {
      width: 50%;
    }
  }
  .description-wrapper {
    h3 {
      font-size: 15px;
      line-height: 24px;
    }
  }
  .image-desc-wrapper {
    grid-template-rows: 150px 1fr;
  }
  .btn {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .description-wrapper {
    h3 {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  .name-wrapper {
    h1 {
      margin-top: 10px;
    }
  }
  .image-desc-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px 1fr;
  }
  .description-wrapper {
    h3 {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .button-container {
    justify-content: space-between;
    flex-direction: row;
    margin: 0;

    .btn {
      margin: 0;
    }
  }
  .response-wrapper {
    margin: 0 10%;
    justify-content: flex-start;
  }
}
</style>
