import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import VueScrollTo from "vue-scrollto";
import Carousel3d from "vue-carousel-3d";

import VueAwesomeSwiper from "vue-awesome-swiper";
import VModal from "vue-js-modal";
import VueAnalytics from "vue-analytics";

Vue.use(VModal);
Vue.use(Carousel3d);
Vue.use(VueAwesomeSwiper);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 800,
  easing: "ease",
  offset: 0,
  cancelable: true,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.use(VueAnalytics, {
  id: "UA-77923382-5",
  router
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
