import Vue from "vue";
import Vuex from "vuex";

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import homepage from "./homepage/index";

Vue.use(Vuex);

const state = {
  metadata: {
    title: "rewind2018",
    description: "",
    ogImage: "",
  },
  loading: {
    status: true,
    message: ["loading"],
  },
  menuStatus: false,
};

export default new Vuex.Store({
  state,
  modules: {
    homepage,
  },
  getters,
  mutations,
  actions,
  strict: true,
});
