let getHomepage = function(state) {
  return state;
};

let getPov = state => {
  return state.povs;
};

export default {
  getHomepage,
  getPov,
};
