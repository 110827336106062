// package: app
// file: service_app.proto

var service_app_pb = require("./service_app_pb");
var grpc = require("grpc-web-client").grpc;

var App = (function () {
  function App() {}
  App.serviceName = "app.App";
  return App;
}());

App.GetVideos = {
  methodName: "GetVideos",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VoidRequest,
  responseType: service_app_pb.VideosResponse
};

App.GetArticles = {
  methodName: "GetArticles",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VoidRequest,
  responseType: service_app_pb.ArticlesResponse
};

App.GetQuotes = {
  methodName: "GetQuotes",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VoidRequest,
  responseType: service_app_pb.QuotesResponse
};

App.GetPovs = {
  methodName: "GetPovs",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VoidRequest,
  responseType: service_app_pb.PovsResponse
};

App.GetAds = {
  methodName: "GetAds",
  service: App,
  requestStream: false,
  responseStream: false,
  requestType: service_app_pb.VoidRequest,
  responseType: service_app_pb.AdsResponse
};

exports.App = App;

function AppClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AppClient.prototype.getVideos = function getVideos(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetVideos, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getArticles = function getArticles(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetArticles, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getQuotes = function getQuotes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetQuotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getPovs = function getPovs(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetPovs, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

AppClient.prototype.getAds = function getAds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  grpc.unary(App.GetAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
};

exports.AppClient = AppClient;

