/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var models_pb = require('./models_pb.js');
goog.exportSymbol('proto.app.AdsResponse', null, global);
goog.exportSymbol('proto.app.ArticlesResponse', null, global);
goog.exportSymbol('proto.app.PovsResponse', null, global);
goog.exportSymbol('proto.app.QuotesResponse', null, global);
goog.exportSymbol('proto.app.VideosResponse', null, global);
goog.exportSymbol('proto.app.VoidRequest', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VoidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.app.VoidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VoidRequest.displayName = 'proto.app.VoidRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VoidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VoidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VoidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VoidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VoidRequest}
 */
proto.app.VoidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VoidRequest;
  return proto.app.VoidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VoidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VoidRequest}
 */
proto.app.VoidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VoidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VoidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VoidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VoidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.VideosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.VideosResponse.repeatedFields_, null);
};
goog.inherits(proto.app.VideosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.VideosResponse.displayName = 'proto.app.VideosResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.VideosResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.VideosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.VideosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.VideosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VideosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    videosList: jspb.Message.toObjectList(msg.getVideosList(),
    models_pb.Video.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.VideosResponse}
 */
proto.app.VideosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.VideosResponse;
  return proto.app.VideosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.VideosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.VideosResponse}
 */
proto.app.VideosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Video;
      reader.readMessage(value,models_pb.Video.deserializeBinaryFromReader);
      msg.addVideos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.VideosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.VideosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.VideosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.VideosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVideosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Video.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Video Videos = 1;
 * @return {!Array<!proto.models.Video>}
 */
proto.app.VideosResponse.prototype.getVideosList = function() {
  return /** @type{!Array<!proto.models.Video>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Video, 1));
};


/** @param {!Array<!proto.models.Video>} value */
proto.app.VideosResponse.prototype.setVideosList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Video=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Video}
 */
proto.app.VideosResponse.prototype.addVideos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Video, opt_index);
};


proto.app.VideosResponse.prototype.clearVideosList = function() {
  this.setVideosList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.ArticlesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.ArticlesResponse.repeatedFields_, null);
};
goog.inherits(proto.app.ArticlesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.ArticlesResponse.displayName = 'proto.app.ArticlesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.ArticlesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.ArticlesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.ArticlesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.ArticlesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ArticlesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    articlesList: jspb.Message.toObjectList(msg.getArticlesList(),
    models_pb.Article.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.ArticlesResponse}
 */
proto.app.ArticlesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.ArticlesResponse;
  return proto.app.ArticlesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.ArticlesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.ArticlesResponse}
 */
proto.app.ArticlesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Article;
      reader.readMessage(value,models_pb.Article.deserializeBinaryFromReader);
      msg.addArticles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.ArticlesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.ArticlesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.ArticlesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.ArticlesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArticlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Article.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Article Articles = 1;
 * @return {!Array<!proto.models.Article>}
 */
proto.app.ArticlesResponse.prototype.getArticlesList = function() {
  return /** @type{!Array<!proto.models.Article>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Article, 1));
};


/** @param {!Array<!proto.models.Article>} value */
proto.app.ArticlesResponse.prototype.setArticlesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Article=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Article}
 */
proto.app.ArticlesResponse.prototype.addArticles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Article, opt_index);
};


proto.app.ArticlesResponse.prototype.clearArticlesList = function() {
  this.setArticlesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.QuotesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.QuotesResponse.repeatedFields_, null);
};
goog.inherits(proto.app.QuotesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.QuotesResponse.displayName = 'proto.app.QuotesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.QuotesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.QuotesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.QuotesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.QuotesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.QuotesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    quotesList: jspb.Message.toObjectList(msg.getQuotesList(),
    models_pb.Quote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.QuotesResponse}
 */
proto.app.QuotesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.QuotesResponse;
  return proto.app.QuotesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.QuotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.QuotesResponse}
 */
proto.app.QuotesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Quote;
      reader.readMessage(value,models_pb.Quote.deserializeBinaryFromReader);
      msg.addQuotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.QuotesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.QuotesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.QuotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.QuotesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Quote.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Quote Quotes = 1;
 * @return {!Array<!proto.models.Quote>}
 */
proto.app.QuotesResponse.prototype.getQuotesList = function() {
  return /** @type{!Array<!proto.models.Quote>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Quote, 1));
};


/** @param {!Array<!proto.models.Quote>} value */
proto.app.QuotesResponse.prototype.setQuotesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Quote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Quote}
 */
proto.app.QuotesResponse.prototype.addQuotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Quote, opt_index);
};


proto.app.QuotesResponse.prototype.clearQuotesList = function() {
  this.setQuotesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.PovsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.PovsResponse.repeatedFields_, null);
};
goog.inherits(proto.app.PovsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.PovsResponse.displayName = 'proto.app.PovsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.PovsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.PovsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.PovsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.PovsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.PovsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    povsList: jspb.Message.toObjectList(msg.getPovsList(),
    models_pb.Pov.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.PovsResponse}
 */
proto.app.PovsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.PovsResponse;
  return proto.app.PovsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.PovsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.PovsResponse}
 */
proto.app.PovsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Pov;
      reader.readMessage(value,models_pb.Pov.deserializeBinaryFromReader);
      msg.addPovs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.PovsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.PovsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.PovsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.PovsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPovsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Pov.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Pov Povs = 1;
 * @return {!Array<!proto.models.Pov>}
 */
proto.app.PovsResponse.prototype.getPovsList = function() {
  return /** @type{!Array<!proto.models.Pov>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Pov, 1));
};


/** @param {!Array<!proto.models.Pov>} value */
proto.app.PovsResponse.prototype.setPovsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Pov=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Pov}
 */
proto.app.PovsResponse.prototype.addPovs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Pov, opt_index);
};


proto.app.PovsResponse.prototype.clearPovsList = function() {
  this.setPovsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.AdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.AdsResponse.repeatedFields_, null);
};
goog.inherits(proto.app.AdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.app.AdsResponse.displayName = 'proto.app.AdsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.AdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.AdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.app.AdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.AdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.AdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adsList: jspb.Message.toObjectList(msg.getAdsList(),
    models_pb.Ads.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.AdsResponse}
 */
proto.app.AdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.AdsResponse;
  return proto.app.AdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.AdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.AdsResponse}
 */
proto.app.AdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new models_pb.Ads;
      reader.readMessage(value,models_pb.Ads.deserializeBinaryFromReader);
      msg.addAds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.AdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.AdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.AdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.AdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      models_pb.Ads.serializeBinaryToWriter
    );
  }
};


/**
 * repeated models.Ads Ads = 1;
 * @return {!Array<!proto.models.Ads>}
 */
proto.app.AdsResponse.prototype.getAdsList = function() {
  return /** @type{!Array<!proto.models.Ads>} */ (
    jspb.Message.getRepeatedWrapperField(this, models_pb.Ads, 1));
};


/** @param {!Array<!proto.models.Ads>} value */
proto.app.AdsResponse.prototype.setAdsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.models.Ads=} opt_value
 * @param {number=} opt_index
 * @return {!proto.models.Ads}
 */
proto.app.AdsResponse.prototype.addAds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.models.Ads, opt_index);
};


proto.app.AdsResponse.prototype.clearAdsList = function() {
  this.setAdsList([]);
};


goog.object.extend(exports, proto.app);
