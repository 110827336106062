<template>
  <div id="app">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import _ from "lodash";
import Header from "@/components/shared/header";
export default {
  components: {
    Header
  },
  mounted() {
    setTimeout(
      function() {
        let hash = this.$route.hash;
        //console.log(hash)
        if (hash && hash !== null) {
          let id = hash.substring(1, hash.length);
          this.$scrollTo("#" + id);
        }

        document.documentElement.style.setProperty(
          `--width`,
          window.innerWidth
        );
        document.documentElement.style.setProperty(
          `--height`,
          window.innerHeight
        );
      }.bind(this),
      500
    );

    document.addEventListener(
      "mousemove",
      _.debounce(event => {
        document.documentElement.style.setProperty(`--x`, event.clientX);
        document.documentElement.style.setProperty(`--y`, event.clientY);
      }, 3)
    );
  }
};
</script>


<style lang="scss">
#app {
  font-family: var(--font-roboto);
  background: #fff;
}

*,
:before,
:after {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.main-wrapper {
  display: grid;
  grid-template-columns:
    [full-start] minmax(5vw, 1fr) [container-start] minmax(300px, 1300px)
    [container-end] minmax(5vw, 1fr) [full-end];
  grid-template-rows: auto;
}

.section-container {
  grid-column: container;
}

.full-section-container {
  grid-column: full;

  &.has-sub-containers {
    display: grid;
    grid-template-columns:
      [full-start] minmax(5vw, 1fr) [container-start] minmax(300px, 1300px)
      [container-end] minmax(5vw, 1fr) [full-end];
    grid-template-rows: auto;

    .full-section-container {
      grid-column: full;
    }

    .section-container {
      grid-column: container;
    }
  }
}

[data-layout="home"] {
  overflow-x: hidden;
}
</style>
