<template>
  <div class="quiz-container">
    <div class="title">
      <h1>{{ quiz.title }}</h1>
    </div>

    <div class="questions__wrapper">
      <transition name="fade" mode="out-in">
        <template v-for="(question, index) in quiz.questions">
          <div :key="index" v-if="index === questionIndex">
            <div class="question-title">
              <h3>{{ question.text }}</h3>
            </div>
            <ul class="selection-wrapper">
              <li v-for="(response, num) in question.responses" :key="num" class="selection">
                <input
                  type="radio"
                  :id="response.text"
                  :value="response.value"
                  :name="index"
                  v-model="userResponses[index]"
                  @click="checkStatus()"
                >
                <label :for="response.text">{{response.text}}</label>
              </li>
            </ul>
          </div>
        </template>
      </transition>

      <div class="button-wrapper">
        <button class="btn-refresh" @click="refreshQuiz()">
          Recommencer le
          quiz
          <i class="fas fa-sync-alt"></i>
        </button>
        <template v-if="questionIndex < 5 ">
          <button :disabled="buttonState" class="btn-success" v-on:click="next">
            Continuer
            <i class="fas fa-arrow-right"></i>
          </button>
        </template>
        <template v-else>
          <button :disabled="buttonState" class="btn-success" v-on:click="submitQuiz">
            Voir le resultat
            <i class="fas fa-check-circle"></i>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import question from "@/components/quiz/question.json";
export default {
  data() {
    return {
      quiz: question,
      questionIndex: 0,
      userResponses: [],
      result: null,
      buttonState: true
    };
  },
  mounted() {},
  methods: {
    next() {
      this.questionIndex++;
      this.buttonState = true;
    },
    refreshQuiz() {
      this.questionIndex = 0;
      this.userResponses = [];
      this.buttonState = true;
    },
    submitQuiz() {
      this.questionIndex++;
      this.buttonState = true;
      localStorage.setItem("isPlayed", "true");
      // named route
      this.$router.push({
        name: "response",
        params: { name: this.getResult() }
      });
    },
    prev() {
      this.questionIndex--;
    },
    getResult() {
      //find the highest occurence in responses
      var modeMap = {};
      var maxEl = this.userResponses[0],
        maxCount = 1;
      for (var i = 0; i < this.userResponses.length; i++) {
        var el = this.userResponses[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }
      return maxEl;
    },
    checkStatus() {
      this.buttonState = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.quiz-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-position: center bottom !important;
  background: url("../../assets/images/pov_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0;
}

.questions__wrapper {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.title {
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.4);
  width: 100%;
  padding-bottom: 40px;
  background-position: center bottom !important;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/caricature/quiz-banner.jpg");
  background-repeat: no-repeat;

  h1 {
    margin: 0 auto;
    color: #fff;
    font-weight: 400;
    font-size: 55px;
    text-align: center;
    text-transform: capitalize;
    padding-top: 30px;
    font-family: var(--font-playfair);
    padding-bottom: 10px;
    letter-spacing: 0.025em;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.question-title {
  max-width: 75%;
  margin: 0 auto;

  h3 {
    margin: 6% 0;
    font-weight: 400;
    color: var(--h2-color);
    font-size: 40px;
    font-family: var(--font-playfair);
    text-align: center;
    letter-spacing: -1px;
    line-height: 45x;
  }
}

[v-cloak] {
  display: none;
}

.selection-wrapper {
  padding: 0;
  list-style: none;
  margin-bottom: 60px;
}

.selection {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;

  label {
    display: inline-block;
    background-color: #c3cfe2;
    border-radius: 5px;
    color: #3b3b3b;
    font-weight: 500;
    padding: 15px 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin: 0px auto;
    font-size: 20px;
    min-width: 50%;
    max-width: 50%;
    font-family: var(--font-roboto);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);

    &:hover {
      background-color: #01396d;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
      color: #fff;
    }
  }

  input[type="radio"]:checked ~ label {
    background: #01396d;
    color: #fff;
  }
}

input[type="radio"] {
  display: none;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.btn-refresh,
.btn-success {
  padding: 15px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  width: 230px;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);

  i {
    margin-left: 10px;
  }
}

.btn-refresh {
  background: #3b3b3b;
  color: #fff;

  &:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.8);
  }
}

.btn-success {
  color: #ffffff;
  background: #00b660;

  &:disabled {
    background: rgb(164, 164, 164);
    cursor: default;
  }
}

@media (max-width: 1600px) {
  .question-title {
    h3 {
      margin: 30px 0;
    }
  }
}

@media (max-width: 1400px) {
  .selection-wrapper {
    margin-bottom: 20px;
  }
  .title {
    h1 {
      font-size: 40px;
    }
  }
  .question-title {
    h3 {
      margin: 20px 0;
      font-size: 30px;
    }
  }
  .selection {
    label {
      font-size: 15px;
      padding: 10px 20px;
    }
  }
}

@media (max-width: $desktop) {
  .quiz-container {
  }
  .title {
    h1 {
    }
  }
  .question-title {
    h3 {
    }
  }
  .selection {
    label {
    }
  }
}

@media (max-width: $tablet) {
  .selection {
    label {
    }
  }
  .question-title {
    margin: 20px auto;

    h3 {
      text-align: center;
    }
  }

  .question-title {
    h3 {
      font-size: 24px;
    }
  }

  .selection {
    label {
      font-size: 15px;
    }
  }

  .button-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }

  .btn-success {
    margin-bottom: 20px;
  }
}

@media (max-width: $mobile) {
  .title {
    h1 {
      margin: 0 auto;
      font-size: 18px;
      max-width: 75%;
    }
  }
  .quiz-container {
    padding: 0;
  }
  .selection {
    label {
      font-size: 15px;
      min-width: 70%;
      max-width: 70%;
    }
  }
  .button-wrapper {
    justify-content: center;
  }
  .btn-success {
  }
  .btn-refresh,
  .btn-success {
    font-size: 15px;
    padding: 10px;
  }
  .question-title {
    h3 {
      font-size: 20px;
      margin: 0 20px;
    }
  }
}

@media (max-width: 320px) {
  .title {
    padding-bottom: 20px;

    h1 {
      padding-top: 20px;
    }
  }
  .question-title {
    margin: 10px auto;
    h3 {
      font-size: 12px;
    }
  }
  .selection {
    label {
      padding: 5px 20px;
      font-size: 10px;
    }
  }
  .btn-success {
    margin-bottom: 10px;
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  .title {
    padding-bottom: 20px;
    h1 {
      font-size: 20px;
    }
  }
  .question-title {
    margin: 10px auto;
    h3 {
      font-size: 18px;
    }
  }
  .selection-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin: 0 20px;
  }
  .selection {
    margin: 0;
    label {
      margin: 0;
      min-width: 50%;
      max-width: 100%;
      font-size: 12px;
    }
  }
  .button-wrapper {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 10px;
  }
  .btn-success {
    margin: 0;
  }
  .btn-refresh,
  .btn-success {
    padding: 10px;
    font-size: 12px;
  }
}
</style>
