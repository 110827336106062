import { VoidRequest } from "@/services/service_app_pb";
import { grpcFetch } from "@/utils/manager.js";
import _ from "lodash";

let loadHomepage = ({ state, commit }) => {
  /**** Loader ****/
  commit(
    "SET_STATUS",
    {
      status: true,
      message: "Fetching deck data"
    },
    {
      root: true
    }
  );

  if (!state.fetchedHomepage) {
    let request = new VoidRequest();
    let articlesProm = grpcFetch("getArticles", request);
    let quotesProm = grpcFetch("getQuotes", request);
    let povsProm = grpcFetch("getPovs", request);
    let videosProm = grpcFetch("getVideos", request);
    let adsProm = grpcFetch("getAds", request);

    Promise.all([articlesProm, quotesProm, povsProm, videosProm, adsProm]).then(
      function(values) {
        // console.log("homepage data: ", values);
        commit("setFetchedHomepage", {
          value: true
        });

        let videos = values[3].getVideosList();

        //Sort videos in ASC order
        videos.sort(function(a, b) {
          return b.getPublishedat() - a.getPublishedat();
        });

        let videosFeatured = _.filter(videos, video => {
          return video.getIsfeatured();
        });

        let videosNotFeatured = _.filter(videos, video => {
          return !video.getIsfeatured();
        });

        let videosByDate = _.groupBy(videosNotFeatured, video => {
          return (
            video
              .getPublishedat()
              .toDate()
              .getMonth() + 1
          );
        });

        let articles = values[0].getArticlesList();

        //Sort articles in ASC order
        articles.sort(function(a, b) {
          return b.getPublishedat() - a.getPublishedat();
        });

        let articlesByMonth = _.groupBy(articles, article => {
          return (
            article
              .getPublishedat()
              .toDate()
              .getMonth() + 1
          );
        });

        let quotes = values[1].getQuotesList();
        //Sort quotes in ASC order
        quotes.sort(function(a, b) {
          return b.getPublishedat() - a.getPublishedat();
        });

        let povs = values[2].getPovsList();
        //Sort povs in ASC order
        povs.sort(function(a, b) {
          return a.getPublishedat() - b.getPublishedat();
        });

        let ads = values[4].getAdsList();
        //Sort ads in ASC order
        ads.sort(function(a, b) {
          return b.getPublishedat() - a.getPublishedat();
        });

        commit("setArticlesByMonth", articlesByMonth);
        commit("setArticles", articles);
        commit("setQuotes", quotes);
        commit("setPovs", values[2].getPovsList());
        commit("setVideos", videos);
        commit("setVideosByDate", videosByDate);
        commit("setVideosFeatured", videosFeatured);
        commit("setAds", ads);
      }
    );
  } else {
    /**** Loader ****/
    commit(
      "SET_STATUS",
      {
        status: false,
        message: "Has already fetched homepage data"
      },
      {
        root: true
      }
    );
    /**** Loader ****/
  }
};

export default {
  loadHomepage
};
