import { AppClient } from "@/services/service_app_pb_service.js";

// const loadFromNetwork = function (method, request) {
//     return new Promise((resolve, reject) => {
//         let client = new AppClient("/api");

//         console.log(`🔌 Loading ${method} from network`);

//         client[method](request, (err, response) => {
//             if (err) {
//                 console.log(err);
//                 reject(err);
//             } else {
//                 console.log(request);
//                 resolve(response);
//             }
//         });
//     });
// };

const grpcFetch = function (method, req) {
    return new Promise((resolve, reject) => {
        let client = new AppClient("/api");
        console.log(`Loading ${method} from network`);
        client[method](req, (err, response) => {
            if (err) {
                console.log(err);
                reject(err);
            } else {
                resolve(response);
            }
        });
    });
};
export { grpcFetch }