import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  fetchedHomepage: false,
  articlesByMonth: [],
  articles: [],
  videos: [],
  videosByDate: [],
  videosFeatured: [],
  quotes: [],
  povs: [],
  ads: [],
};

const namespaced = true;

export default {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
