let setFetchedHomepage = (state, payload) => {
  state.fetchedHomepage = payload.value;
};

let setArticlesByMonth = (state, payload) => {
  state.articlesByMonth = payload;
};

let setArticles = (state, payload) => {
  state.articles = payload;
  //state.articles.push(payload);
};

let setVideos = (state, payload) => {
  state.videos = payload;
};

let setVideosByDate = (state, payload) => {
  state.videosByDate = payload;
};

let setVideosFeatured = (state, payload) => {
  state.videosFeatured = payload;
};

let setQuotes = (state, payload) => {
  state.quotes = payload;
};

let setPovs = (state, payload) => {
  state.povs = payload;
};

let setAds = (state, payload) => {
  state.ads = payload;
};

export default {
  setFetchedHomepage,
  setArticlesByMonth,
  setArticles,
  setVideos,
  setVideosByDate,
  setVideosFeatured,
  setQuotes,
  setPovs,
  setAds,
};
